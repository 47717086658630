import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import HeroComponent from "./pages/HeroComponent";
import Home from "./pages/Home";
import Results from "./pages/Results";
import LoadingScreen from "./components/LoadingScreen";
import Disclaimer from "./pages/Disclaimer";
import PopupSecurity from "./components/PopupSecurity";
import Applibrary from "./pages/AppLibrary";
import AppResults from "./pages/AppResults";
import Video from "./pages/Video";



import "./assets/scss/style.scss";
import "./assets/css/main.css";


const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous loading process
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
{/* <PopupSecurity /> */}
      <PageScrollTop>
        {!isLoading ? (
          <Switch>
            <Route exact path="/" component={HeroComponent} />
            <Route path="/HeroComponent" component={HeroComponent} />
            <Route path="/Home" component={Home} />
            <Route path="/Results/:elementName" component={Results} />
            {/* Define the Results route with the :elementName parameter */}
            <Route path="/AppLibrary" component={Applibrary} />
            <Route path="/AppResults/:elementName" component={AppResults} />
            <Route path="/Video" component={Video} />
            <Route path="/Disclaimer" component={Disclaimer} />
          </Switch>
        ) : (
          <LoadingScreen />
        )}
      </PageScrollTop>
    </Router>
  );
};

export default React.memo(App);
