import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ContentDataV2 from "../data/AppContentData.json";
import SEO from "../common/SEO";
import HeaderTwo from "../common/header/HeaderTwo";

const AppResults = () => {
  const history = useHistory();
  const { elementName } = useParams(); // Accept URL Params from Home Page
  const [selectedContent, setSelectedContent] = useState(null);

    useEffect(() => {
      // Function to find content by key and set it in the state
      const findContentByKey = (key) => {
        for (const contentItem of ContentDataV2.content) {
          console.log(contentItem);
          if (contentItem.id.includes(key)) {
  
            console.log("FOUND" + contentItem);
            return contentItem;
          }
        }
        return null; // If not found
      };
  
      // Try to get content from history state, fallback to URL parameter
      const getContentFromHistoryOrURL = () => {
        const state = history.location.state ?? { content: findContentByKey(elementName) }; // Provide a default value for content
          if (state && state.content) {
          return state.content;
        } else {
          return findContentByKey(elementName);
        }
      };
  
      const content = getContentFromHistoryOrURL();
      setSelectedContent(content);
  
    }, [elementName, history]);
    
  return (
    <div>
      <script
        type="module"
        src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
      ></script>

      <SEO title="TVA X Interactions" />
      <main className="page-wrapper ">
        {/* <HeaderTopNews /> */}

        <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

        {selectedContent && selectedContent.ProjectFolder ? (
          <div>
            <div className="unity_col-lg-8">
              <div className="unity_container">
                <div className="unity_iframe__container ">
                  <iframe
                    title="UnityIframe"
                    className="iframe_new unity-iframe"
                    src={"/apps" + selectedContent.ProjectFolder + "index.html"}
                    allow="autoplay"
                  ></iframe>
                </div>
                <div id="tutorial__anchor_model"></div>
              </div>
            </div>
            <div className="unity_col-lg-4 unity__frame_size"></div>
          </div>
        ) : (
          <p>404 - The content could not be found, the URL may be incorrect or the content may have moved. Please submit a bugreport if you keep encountering this issue. Thanks!</p>
        )}
      </main>
      {/* Displaying the copyright information */}
      <div className="footer__ancor unity__frame_size">
      {/* <Copyright /> */}
      </div>
    </div>
  );
};

export default React.memo(AppResults);
